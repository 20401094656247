import React, { Component } from 'react';

export class Home extends Component {
  static displayName = Home.name;

  render() {
    return (
      <div>
        <h1>Wait for it. We're working on something amazing...</h1>
            <img src="../images/gif1.gif" width="100%" />
        </div>
    );
  }
}
